import React from "react"
import { Link } from "gatsby"
import Logo from "../assets/Createlogo.svg"
import { FaAlignRight } from "react-icons/fa"

export default function Navbar({ toggleSidebar }) {
  return (
    <>
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
            <Link to="/">
              <img src={Logo} alt="Logo CedCreate" />
            </Link>
            <button
              type="button"
              className="toggle-btn"
              onClick={toggleSidebar}
            >
              <FaAlignRight></FaAlignRight>
            </button>
          </div>
          <ul className="nav-links">
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="https://cedcreate.com/">Contact</Link>
            </li>
            <li>
              <Link to="/about">A Propos</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}
